<template>
  <b-button @click="logout()" class="is-centered has-text-weight-bold" icon-left="sign-out-alt" id="logout" rounded>
    {{ $t('logout') }}
  </b-button>
</template>

<script>
export default {
  name: "ButtonLogout",
}
</script>

<style lang="scss" scoped></style>
