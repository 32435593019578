<script setup>
import ButtonSignUp from "./ButtonSignUp.vue";
import ButtonLogin from "./ButtonLogin.vue";
import ButtonLogout from "./ButtonLogout.vue";
</script>

<template>
  <div>
    <b-navbar class="has-text-weight-bold has-text-black" :mobile-burger="false">
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img src="/static/images/header.svg" alt="HStorage - 直リンクできる無料のファイル共有アップローダー" loading="lazy" width="145.45"
            height="40">
        </b-navbar-item>
        <!-- モバイル専用 -->
        <b-navbar-item v-if="$isMobile()" tag="div" style="margin: 0 0 0 auto">
          <b-dropdown aria-role="list">
            <template #trigger="{ active }">
              <b-button type="is-light" :icon-right="active ? 'chevron-down' : 'chevron-right'"
                class="has-text-weight-bold">メニュー</b-button>
            </template>
            <b-dropdown-item aria-role="listitem" href="#tokutyou">{{ $t('top.tokutyou.title') }}</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" href="#features">{{ $t('top.features.title') }}</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" href="/about/security">セキュリティについて知る</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" href="/about/dashboard">ファイル管理について知る</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" href="/subscription">{{ $t('subscription.title') }}</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" href="/faq">{{ $t('faq.title') }}</b-dropdown-item>
            <b-dropdown-item v-if="!$store.state.isLoggedIn" tag="div">
              <div class="buttons is-centered">
                <ButtonLogin />
                <ButtonSignUp />
              </div>
            </b-dropdown-item>
            <div v-if="$store.state.isLoggedIn">
              <b-dropdown-item :separator="true"></b-dropdown-item>
              <b-dropdown-item aria-role="listitem"> {{ $store.state.email }}</b-dropdown-item>
              <div class="buttons is-centered margin-top-12px">
                <ButtonLogout size="is-small" />
              </div>
            </div>
          </b-dropdown>
        </b-navbar-item>
      </template>
      <!-- ここから先は PC でのみ表示される -->
      <template #start>
        <b-navbar-item href="#tokutyou">{{
          $t('top.tokutyou.title')
        }}
        </b-navbar-item>
        <b-navbar-item href="#features">{{
          $t('top.features.title')
        }}
        </b-navbar-item>
        <b-navbar-item tag="router-link" to="/subscription" id="navbar-subscription">{{
          $t('subscription.title')
        }}
        </b-navbar-item>
        <b-navbar-item href="/faq">{{ $t('faq.title') }}</b-navbar-item>
      </template>

      <template #end>
        <b-navbar-item v-if="!$store.state.isLoggedIn" tag="div">
          <div class="buttons is-centered">
            <ButtonLogin />
            <ButtonSignUp />
          </div>
        </b-navbar-item>
        <b-navbar-dropdown :label="$store.state.email" v-if="$store.state.isLoggedIn" id="navbar-dropdown">
          <div class="buttons is-centered margin-top-12px">
            <ButtonLogout size="is-small" />
          </div>
        </b-navbar-dropdown>
      </template>
    </b-navbar>
  </div>
</template>

<style lang="scss" scoped>
.navbar-item img {
  max-height: 40px;
}

div.navbar-brand {
  background-color: #222f3d;
  min-height: 4rem !important;
}
</style>
