export default {
  methods: {
    toastError (msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: `<b>${msg}</b>`,
        position: 'is-top',
        type: 'is-danger'
      })
    },
    toastInfo (msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: `<b>${msg}</b>`,
        position: 'is-top',
        type: 'is-info'
      })
    }
  }
}
