<template>
  <b-button v-if="$store.state.isLoggedIn && !$store.state.isLoginProcessing" icon-pack="fas" icon-right="rocket"
    class="is-primary is-focused has-text-weight-bold" @click="go" rounded>
    {{ $t('dashboard.title') }}
  </b-button>
</template>

<script>
export default {
  name: "ButtonDashboard",
  methods: {
    go() {
      this.$router.push('/dashboard').catch(() => {
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
