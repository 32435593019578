export default {
  methods: {
    EmailFile: function () {
      if (this.$store.state.emailFile.email === null || this.$store.state.emailFile.email === '') {
        this.$buefy.dialog.alert('メールアドレスが正しくありません')
        return
      }

      this.openLoading()

      this.$axios.post(`/file/email?email=${this.$store.state.emailFile.email}`, this.$store.state.emailFile.file, {headers: {'Authorization': `Bearer ${this.$store.state.token}`}})
        .then(() => {
          this.$buefy.dialog.alert('メールが送信されました')
          this.closeLoading()
          this.$store.state.emailFile.isModalOpen = false
        })
        .catch(err => {
          new Error(err) // Todo ?
          this.closeLoading()
          this.$store.state.emailFile.isModalOpen = false
        })
    },
    EmailFileModal: function (file) {
      this.$store.state.emailFile.isModalOpen = true
      this.$store.state.emailFile.file = file
    }
  }
}
