import { createI18n } from "vue-i18n";
import { messages } from "./lang";

export const i18n = createI18n({
  legacy: true,
  warnHtmlMessage: false, // not working
  locale: "ja",
  fallbackLocale: "ja",
  messages,
});
