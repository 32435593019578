<template>
  <b-button v-if="!$store.state.isLoggedIn && !$store.state.isLoginProcessing" id="login" data-id="login"
    @click="login(redirect_uri)" class="has-text-weight-bold" rounded>{{
      $t('login') }}
  </b-button>
  <!-- login ボタンコンポーネントで Loading 処理を設定する -->
  <b-loading v-if="$store.state.isLoginProcessing" v-model="$store.state.isLoginProcessing">
    <img src="/static/images/loading.svg" alt="loading">
  </b-loading>
</template>

<script>
export default {
  name: "ButtonLogin",
  props: {
    redirect_uri: {
      type: String,
      default: '/dashboard'
    }
  },
}
</script>

<style lang="scss" scoped></style>
