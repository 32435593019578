<script>
import ButtonDashboard from "./ButtonDashboard.vue";

export default {
  name: "ButtonDashboardBottom",
  components: {ButtonDashboard},
}
</script>

<template>
  <ButtonDashboard/>
</template>

<style scoped lang="scss">

</style>
