export default {
  methods: {
    login(redirectUri) {
      this.$auth0.loginWithRedirect({
        appState: {
          target: redirectUri,
        },
        authorizationParams: {
          screen_hint: "login",
          redirect_uri: `${window.location.origin}`,
        },
      });
    },
    signUp() {
      this.$auth0.loginWithRedirect({
        authorizationParams: {
          screen_hint: "signup",
          //redirect_uri: `${window.location.origin}`
          redirect_uri: import.meta.env.VITE_SIGNUP_SURVEYJS,
        },
      });
    },
    logout() {
      this.$auth0.logout({
        logoutParams: {
          federated: false,
          returnTo: window.location.origin,
        },
      });
    },
    WaitingAuth0: async function () {
      try {
        this.openLoading();
        await this.$auth0.checkSession();
        if (this.$store.state.isLoggedIn) {
          this.closeLoading();
        } else {
          this.closeLoading();
          this.login();
        }
      } catch (error) {
        this.closeLoading();
        this.login();
      }
    },
  },
};
