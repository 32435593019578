const __pages_import_0__ = () => import("/src/pages/dashboard/index.vue");
const __pages_import_1__ = () => import("/src/pages/about/security.vue");
const __pages_import_2__ = () => import("/src/pages/about/dashboard.vue");
const __pages_import_3__ = () => import("/src/pages/uploaded.vue");
const __pages_import_4__ = () => import("/src/pages/unsubscription.vue");
const __pages_import_5__ = () => import("/src/pages/tos.vue");
const __pages_import_6__ = () => import("/src/pages/success.vue");
const __pages_import_7__ = () => import("/src/pages/subscription.vue");
const __pages_import_8__ = () => import("/src/pages/privacy.vue");
const __pages_import_9__ = () => import("/src/pages/payment-failed.vue");
const __pages_import_10__ = () => import("/src/pages/maintenance.vue");
const __pages_import_11__ = () => import("/src/pages/it-security-basic-policy.vue");
import __pages_import_12__ from "/src/pages/index.vue";
const __pages_import_13__ = () => import("/src/pages/faq.vue");
const __pages_import_14__ = () => import("/src/pages/error.vue");
const __pages_import_15__ = () => import("/src/pages/delete_account.vue");
const __pages_import_16__ = () => import("/src/pages/contact.vue");
const __pages_import_17__ = () => import("/src/pages/asct.vue");
const __pages_import_18__ = () => import("/src/pages/account_linking.vue");
const __pages_import_19__ = () => import("/src/pages/[...all].vue");
const __pages_import_20__ = () => import("/src/pages/group/[group_uid].vue");
const __pages_import_21__ = () => import("/src/pages/show/[external_id].vue");

const routes = [{"name":"dashboard","path":"/dashboard","component":__pages_import_0__,"props":true},{"name":"about-security","path":"/about/security","component":__pages_import_1__,"props":true},{"name":"about-dashboard","path":"/about/dashboard","component":__pages_import_2__,"props":true},{"name":"uploaded","path":"/uploaded","component":__pages_import_3__,"props":true},{"name":"unsubscription","path":"/unsubscription","component":__pages_import_4__,"props":true},{"name":"tos","path":"/tos","component":__pages_import_5__,"props":true},{"name":"success","path":"/success","component":__pages_import_6__,"props":true},{"name":"subscription","path":"/subscription","component":__pages_import_7__,"props":true},{"name":"privacy","path":"/privacy","component":__pages_import_8__,"props":true},{"name":"payment-failed","path":"/payment-failed","component":__pages_import_9__,"props":true},{"name":"maintenance","path":"/maintenance","component":__pages_import_10__,"props":true},{"name":"it-security-basic-policy","path":"/it-security-basic-policy","component":__pages_import_11__,"props":true},{"name":"index","path":"/","component":__pages_import_12__,"props":true},{"name":"faq","path":"/faq","component":__pages_import_13__,"props":true},{"name":"error","path":"/error","component":__pages_import_14__,"props":true},{"name":"delete_account","path":"/delete_account","component":__pages_import_15__,"props":true},{"name":"contact","path":"/contact","component":__pages_import_16__,"props":true},{"name":"asct","path":"/asct","component":__pages_import_17__,"props":true},{"name":"account_linking","path":"/account_linking","component":__pages_import_18__,"props":true},{"name":"all","path":"/:all(.*)*","component":__pages_import_19__,"props":true},{"name":"group-group_uid","path":"/group/:group_uid","component":__pages_import_20__,"props":true},{"name":"show-external_id","path":"/show/:external_id","component":__pages_import_21__,"props":true}];

export default routes;