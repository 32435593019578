export default {
  methods: {
    Download: function (file, isEncrypt, hashed) {
      return new Promise((resolve, reject) => {
        let url;

        if (isEncrypt) {
          url = `${import.meta.env.VITE_API_URL}/file/get?external_id=${encodeURIComponent(file.external_id)}&hashed=${encodeURIComponent(hashed)}&download=true`;
        } else {
          url = file["download_url"];
        }

        const notifyDownload = () => {
          if (file.user_id !== this.$store.state.user.sub) {
            return this.$axios.get(
              `/file/download-notification?external_id=${encodeURIComponent(file.external_id)}`,
            );
          }
          return Promise.resolve();
        };

        notifyDownload()
          .then(() => {
            // for flutter
            if (window.flutterSendMessage) {
              window.flutterSendMessage.postMessage(
                JSON.stringify({
                  type: "download",
                  message: { fileName: file.file_name, url: url },
                }),
              );
              resolve();
            } else {
              const newWindow = window.open(url, "_blank");
              if (newWindow) {
                newWindow.focus();
                resolve();
              } else {
                reject(new Error("Failed to open new window"));
              }
            }
          })
          .catch(reject);
      });
    },
    /**
     * 文字列をダウンロード可能なファイルとしてユーザーに提供する関数
     * @param {string} content - ダウンロードする内容となる文字列
     * @param {string} fileName - ダウンロードするファイルの名前
     * @param {string} contentType - ファイルのコンテンツタイプ（例: 'text/plain'）
     */
    DownloadStringAsFile: function (content, fileName, contentType) {
      const blob = new Blob([content], { type: contentType });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
