export default {
  data () {
    return {
      isFullPage: true,
      loadingComponent: null
    }
  },
  methods: {
    openLoading () {
      this.loadingComponent = this.$buefy.loading.open({
        container: this.isFullPage ? null : this.$refs.element.$el
      })
    },
    closeLoading () {
      this.loadingComponent.close()
    }
  }
}
