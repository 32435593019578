export default {
  methods: {
    AlertConnectionError() {
      this.$buefy.dialog.alert({
        title: this.$t('error.connection.title'),
        message: this.$t('error.connection.message'),
        type: 'is-danger',
        hasIcon: false,
        ariaRole: 'alertdialog',
        ariaModal: true,
      })
    }
  }
}
