import appConfig from "../app.config.json";

export default {
  methods: {
    GetShareTitle(fileName) {
      return `${fileName} | ${appConfig[this.$i18n.locale].title}`;
    },
    // navigator.share は HTTPS でのみ動作する
    OpenShare(url, title, text) {
      if (navigator.share) {
        navigator
          .share({
            url: url,
            title: title,
            text: text,
          })
          .then(() => {})
          .catch(() => {});
      } else {
        this.$buefy.snackbar.open({
          message: "共有ページのURLをコピーしました",
          type: "is-info",
          position: "is-top",
          indefinite: true,
        });
        this.execCopy(url, "share-url-copy-button");
      }
    },
  },
};
