export default {
  methods: {
    HideAds: function () {
      if (this.$refs.adswrapper) {
        this.$refs.adswrapper.style.display = "none !important";
      }
      if (this.$refs.adsbygoogle) {
        this.$refs.adsbygoogle.style.display = "none !important";
      }
      if (this.$refs["adsbygoogle-noablate"]) {
        this.$refs["adsbygoogle-noablate"].style.display = "none !important";
      }
      if (this.$refs["adsbygoogle adsbygoogle-noablate"]) {
        this.$refs["adsbygoogle adsbygoogle-noablate"].style.display =
          "none !important";
      }
      if (this.$refs["google-auto-placed"]) {
        this.$refs["google-auto-placed"].style.display = "none !important";
      }
    },
  },
};
