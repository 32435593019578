<template data-server-rendered="true">
  <div id="app" data-clarity-unmask="True">
    <component :is="Header"></component>
    <component :is="ButtonDashboardBottom" class="button-dashboard-bottom"></component>

    <div class="container cmain">
      <router-view v-slot="{ Component, route }">
        <Transition name="fade">
          <div :key="route.fullPath">
            <component :is="Component" />
          </div>
        </Transition>
      </router-view>
    </div>

    <component :is="Footer"></component>

    <b-modal v-model="$store.state.modal.direct.open" aria-role="dialog" aria-label="直リンクの注意点" aria-modal>
      <template #default="props">
        <div @close="props.close" class="my-modal">
          <h3>
            <span class="section-title">ご注意ください</span>
          </h3>
          <div class="has-text-weight-bold margin-bottom-30px">
            <p>直リンク URL を開いたあとの URL を利用しないでください。</p>
          </div>
          <h4 class="has-text-weight-bold">正しいURL</h4>
          <p>{{ $store.state.FILE_URL }} から始まる</p>
          <b-button class="is-info has-text-weight-bold margin-top-30px" @click="props.close" rounded>分かりました</b-button>
        </div>
      </template>
    </b-modal>

    <b-modal v-model="$store.state.modal.premium.open" aria-role="dialog" aria-label="プレミアムプラン" aria-modal>
      <template #default="props">
        <Subscription :title="$store.state.modal.premium.title" :description="$store.state.modal.premium.description"
          @close="props.close" class="my-modal"></Subscription>
      </template>
    </b-modal>

    <b-modal v-model="$store.state.modal.relogin.open" aria-role="dialog" aria-label="再ログインのお願い" aria-modal>
      <template #default="props">
        <div @close="props.close" class="my-modal">
          <h3>
            <span class="section-title">再ログインが必要です</span>
          </h3>
          <div class="has-text-weight-bold margin-bottom-30px">
            <p>トークンの有効期限が切れました。再度ログインしてください。</p>
          </div>
          <ButtonLogout />
        </div>
      </template>
    </b-modal>

    <teleport to="head">
      <component :is="'script'" type="application/ld+json">
        {{ jsonld }}
      </component>
    </teleport>
  </div>
</template>

<script>
import appConfig from './app.config'
import ButtonSignUp from "./components/ButtonSignUp.vue";
import ButtonLogout from "./components/ButtonLogout.vue";
import ButtonLogin from "./components/ButtonLogin.vue";
import ButtonDashboard from "./components/ButtonDashboard.vue";
import Footer from "./components/Footer.vue";
import Header from "./components/Header.vue";
import ButtonDashboardBottom from "./components/ButtonDashboardBottom.vue";
import { defineAsyncComponent } from "vue";
import * as Sentry from "@sentry/vue";
import { useRegisterSW } from 'virtual:pwa-register/vue'

// https://vite-pwa-org.netlify.app/guide/periodic-sw-updates.html
const intervalMS = 300000 // 5 minutes
const updateServiceWorker = useRegisterSW({
  onRegistered(r) {
    r && setInterval(() => {
      r.update()
    }, intervalMS)
  }
})

export default {
  name: 'HStorage',
  computed: {
    ButtonDashboardBottom() {
      return ButtonDashboardBottom
    },
    Header() {
      return Header
    },
    Footer() {
      return Footer
    }
  },
  components: {
    Header,
    ButtonDashboard,
    ButtonLogout,
    ButtonSignUp,
    ButtonLogin,
    Footer,
    Subscription: defineAsyncComponent(() => import('./components/SubscriptionComponent.vue'))
  },
  data() {
    return {
      jsonld: {}, // watch で更新する
      isToggled: false,
    }
  },
  watch: {
    '$route.name': function (to, _) {
      this.jsonld = appConfig.jsonld[to]
    },
  },
  async created() {
    this.$axios.get('/init', {})
      .then(response => {
        this.$store.state.init = response.data
        this.$store.state.uploadSetting.maxLargeTotalSize = response.data.setting['max_upload_size']
      })
      .catch(err => {
        this.toastError(this.$t('error.error'))
        throw new Error(err)
      })

    try {
      if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
        const { appState } = await this.$auth0.handleRedirectCallback()
        onRedirectCallback(appState)
      }
    } catch (e) {
      this.error = e
    } finally {
      this.$auth0.getAccessTokenSilently()
        .then(async (token) => {
          this.$store.state.user = this.$auth0.user.value
          this.$store.state.token = token
          this.$store.state.isLoggedIn = this.$auth0.isAuthenticated.value

          if (this.$store.state.user.sub.indexOf('twitter') !== -1 || this.$store.state.user.sub.indexOf('google-oauth2') !== -1 || this.$store.state.user.sub.indexOf('line') !== -1) {
            this.$store.state.email_verified = true
          } else {
            this.$store.state.email_verified = this.$store.state.user.email_verified
          }

          this.$store.state.email = this.$store.state.user.email

          // Twitter は Auth0 Actions によって、user_metadata に入ってくる
          if (this.$store.state.user.sub.indexOf('twitter') !== -1) {
            const userMetadata = this.$store.state.user['https://hstorage.io/user_metadata']
            this.$store.state.email = userMetadata.email
            this.$store.state.user.email = userMetadata.email
          }

          Sentry.setUser({ email: this.$store.state.user.email });
          Sentry.setUser({ id: this.$store.state.user.sub });

          const roles = this.$store.state.user['https://hstorage.io/roles']
          if (typeof roles !== 'undefined') {
            if (roles.indexOf('is_premium') !== -1) {
              this.$store.state.isPremium = true
              this.$store.state.isFree = false
            }
            if (roles.indexOf('is_business') !== -1) {
              this.$store.state.isBusiness = true
              this.$store.state.isFree = false
            }
            if (roles.indexOf('api') !== -1) {
              this.$store.state.isAPIUser = true
            }
          }

          try {
            const res = await this.$axios.get(`/user`, {
              headers: {
                Authorization: `Bearer ${this.$store.state.token}`
              }
            })
            this.$store.commit('updateLoginInit', res.data)
            if (res.data['need_link']) {
              this.$router.push('/account_linking').catch(() => {
              })
            }
          } catch (err) {
            switch (err.response.status) {
              case 0:
                this.logout()
                break
              case 401:
                this.logout()
                break
              case 403:
                this.logout()
                break
              case 404:
                this.logout()
                break
              case 500:
                this.logout()
                break
              default:
                this.$router.push({ name: 'Error', query: { title: 'エラー' } }).catch(() => {
                })
                break
            }
          } finally {
            this.$store.state.isLoginProcessing = false
          }
        }).catch((e) => {
          if (e.error === 'missing_refresh_token' || e.error === 'invalid_grant') {
            this.logout()
          }
        }).finally(() => {
          this.$store.state.isLoginProcessing = false
        })

      try {
        if (this.$store.state.isPremium || this.$store.state.isBusiness) {
          HideAds()
        }
      } catch (e) {
        Sentry.captureException(e)
      }
    }
  },
}
</script>

<style lang="scss">
@import 'bulma/sass/utilities/_all';

$primary: #F24368;
$info: #222f3d;

$family-code: Arial, sans-serif;
$family-primary: Arial, sans-serif;
$body-family: Arial, sans-serif;

// Lists and maps
$custom-colors: null !default;
$custom-shades: null !default;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: mergeColorMaps(("white": ($white,
        $black,
      ),
      "black": ($black,
        $white,
      ),
      "light": ($light,
        $light-invert,
      ),
      "dark": ($dark,
        $dark-invert,
      ),
      "primary": ($primary,
        $primary-invert,
        $primary-light,
        $primary-dark,
      ),
      "link": ($link,
        $link-invert,
        $link-light,
        $link-dark,
      ),
      "info": ($info,
        $info-invert,
        $info-light,
        $info-dark,
      ),
      "success": ($success,
        $success-invert,
        $success-light,
        $success-dark,
      ),
      "warning": ($warning,
        $warning-invert,
        $warning-light,
        $warning-dark,
      ),
      "danger": ($danger,
        $danger-invert,
        $danger-light,
        $danger-dark,
      ),
    ),
    $custom-colors);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// snackbar
$snackbar-border-radius: 18px;

:root {
  --primary-color: $primary;
  --title-font-color: #18181B;
  --default-font-color: rgba(66, 82, 110, var(--tw-text-opacity));
}

// ページ下部に padding が入るのを防ぐ（fixed-bottom が入れてくる）
@media screen and (max-width: 1023px) {

  html.has-navbar-fixed-bottom-touch,
  body.has-navbar-fixed-bottom-touch {
    padding-bottom: 0 !important;
  }
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-weight: 500 !important;
  line-height: 1.8 !important;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  color: var(--default-font-color)
}

#app {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.title {
  color: var(--title-font-color);
}

h1.title {
  line-height: 3rem;
}

h3.title {
  line-height: 3rem;
}

// SP
@media screen and (max-width: 768px) {
  .card-padding {
    padding: 2rem !important;
  }

  .card-padding-mobile {
    padding: 2rem 2rem 0 2rem !important;
  }

  .reverse-columns {
    flex-direction: column-reverse;
    display: flex;
  }

  .margin-top-60px-mobile {
    margin-top: 60px;
  }

  .section-title {
    font-size: 1rem;
  }

  .section-description {
    font-size: 2.1rem;
  }

  #dashboard>div>nav {
    margin-top: 12px;
  }

  // Dashboard のタブを下に固定（モバイル限定）
  nav.tabs {
    bottom: 0;
    position: fixed;
    z-index: 30;
    background-color: rgba(255, 255, 255, 0.98);
    margin-left: -1.5rem;
    height: 60px;
  }

  // ↑ のタブの border を消す
  nav.tabs>ul>li>a {
    border: none;
  }

  // 同上
  // https://dl.hstorage.io/20230529030254_76366d70.png
  nav.tabs>ul {
    width: 100vw;
    border: none;
  }

  // https://dl.hstorage.io/20230529021841_4a6d4c75.png
  .tags.is-centered .tag {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  // end Dashboard.vue
  // List.vue スマホでタグがついてるときの余白
  .taginput .taginput-container>.tag,
  .taginput .taginput-container>.tags {
    margin-top: 6px;
    margin-left: 0 !important;
  }

  .label {
    left: 0 !important;
  }

  // end List.vue
  .hide-on-mobile {
    display: none;
  }

  .bottom-navbar {
    margin-right: -6px;
  }

  .my-modal {
    padding: 3rem 1rem;
  }

  // Dashboard でモバイルの画面下ナビバー
  nav.tabs.is-centered.is-fullwidth {
    background-color: #222f3d;
  }

  // Dashboard でモバイルの画面下ナビバーの文字色
  nav.tabs.is-centered.is-fullwidth>ul>li>a {
    color: white !important;
  }

  // Dashboard でモバイルの画面下ナビバーの文字色
  nav.tabs.is-centered.is-fullwidth>ul>li>a:active {
    color: white !important;
  }

  // Dashboard でモバイルの画面下ナビバーの文字色
  nav.tabs.is-centered.is-fullwidth>ul>li>a:focus {
    color: white !important;
  }

  // Dashboard でモバイルの画面下ナビバーの文字色
  nav.tabs.is-centered.is-fullwidth>ul>li>a:hover {
    color: white !important;
  }
}

@media screen and (min-width: 769px) {
  .card-padding {
    padding: 3.75rem !important;
  }

  .navbar {
    padding: 1rem 10rem;
  }

  .padding-right {
    padding-right: 10rem;
  }

  .section-title {
    font-size: 1rem;
  }

  .section-description {
    font-size: 2.1rem;
  }

  .my-modal {
    padding: 3rem 10rem;
  }
}

@media screen and (min-width: 1024px) {
  .section {
    padding: 3rem 2rem !important;
  }

  .margin-top-60px-desktop {
    margin-top: 60px;
  }

  .section-title {
    font-size: 1.6rem;
  }

  .section-description {
    font-size: 2.5rem;
  }

  .my-modal {
    padding: 3rem 3rem;
  }
}

@media screen and (min-width: 1920px) {}

@media screen and (min-width: 1921px) {
  .navbar {
    padding: 1rem 40rem;
  }

  .section {
    padding: 3rem 10rem !important;
  }

  .padding-right {
    padding-right: 10rem;
  }
}

.container {
  margin: unset !important;
}

.cmain {
  margin-bottom: 60px;
  max-width: 100% !important;
}

.button-dashboard-bottom {
  position: fixed !important;
  right: 20px !important;
  bottom: 90px !important;
  z-index: 2 !important;
}

.bottom-navbar {
  padding: 0;
  background-color: transparent !important;
  //display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  text-align: right;
}

.bottom-navbar>.navbar-brand {
  display: none;
}

div.bottom-navbar>div.navbar-menu.is-active {
  background-color: transparent;
  box-shadow: unset;
}

div.navbar-brand {
  background-color: #222f3d;
  min-height: 4.5rem;
}

.card-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

img {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  image-rendering: -webkit-optimize-contrast;
}

section.tab-content {
  padding: unset !important;
}

.h2-title {
  margin-bottom: 24px;
}

span.icon.is-right {
  width: 1em !important;
  margin-right: 1em !important;
}

span.icon.is-left {
  width: 1em !important;
  margin-right: 1em !important;
}

.icon svg {
  width: 1em;
  height: 1em;
  max-width: 80%;
  max-height: 80%;
}

.box {
  border-radius: 24px !important;
}

hr {
  background-color: #4a4a4a;
  text-align: center;
  margin-bottom: 24px;
}

p.break {
  word-break: break-all;
}

.is-primary {
  background-color: $primary !important;
}

.has-text-info {
  color: #004CA3 !important;
}

.has-text-primary {
  color: $primary !important;
}

a:hover {
  color: unset !important;
}

a.has-text-grey-light:hover {
  color: #b5b5b5 !important;
}

a.has-text-primary:hover {
  color: $primary !important;
}

.has-text-normal {
  color: unset !important;
}

a.has-text-normal:visited {
  color: unset !important;
}

a.has-text-normal:hover {
  color: unset !important;
}

.button.is-info {
  background-color: #222f3d !important;
}

.button.is-primary {
  color: #fff;
}

.button.is-primary:hover {
  color: #fff;
}

.button.is-success {
  background-color: #283149;
}

.button.is-success:hover {
  background-color: #283149;
}

button {
  border-radius: 6px;
}

button.modal-close {
  background-color: #4a4a4a;
}

.button.is-small {
  border-radius: 5px;
  font-weight: bold;
}

p.has-text-no-support {
  color: $primary
}

.notices .toast {
  opacity: unset;
}

.navbar-title {
  color: var(--default-font-color);
  font-size: 24px;
  margin: 0 60px 0 0;
}

.navbar-title-sp {
  margin: 0 3rem 0 1rem;
  color: var(--default-font-color);
  font-size: 30px;
}

.navbar {
  padding: 0 1rem;
  background-color: #222f3d !important;
}

.navbar-burger {
  height: auto !important;
}

a.navbar-item {
  color: white;
}

a.navbar-item.router-link-exact-active.router-link-active {
  color: black;
}

a.navbar-link {
  background-color: transparent;
  color: white;
}

a.navbar-link:active {
  color: white;
}

a.navbar-link:focus {
  color: black;
}

.navbar-link:not(.is-arrowless)::after {
  border-color: white !important;
}

.navbar-link:not(.is-arrowless):hover::after {
  border-color: black !important;
}

.navbar-link:not(.is-arrowless):focus::after {
  border-color: black !important;
}

a.twitter {
  color: #404b69
}

.notify-failed {
  padding: 10px;
  margin: 0 5px 5px;
  color: #ffffff;
  background: $primary !important;
  border-left: 5px solid #DEF1FD;
}

.notify-failed .notification-title {
  background-color: $primary !important;
}

.notify-failed .notification-content {
  background-color: $primary !important;
}

.cmessage-error {
  margin: 6rem 0 12px 0;
  color: $primary;
}

.section-title {
  display: block;
  font-weight: bold;
  letter-spacing: 0.8px;
  color: $primary;
  margin-bottom: 12px;
}

.section-description {
  font-weight: bold;
  color: var(--title-font-color)
}

.rainbow {
  background: radial-gradient(circle at 50% 50%, rgba(238, 53, 115, 1) 0%, rgba(73, 116, 187, 1) 50%, rgba(249, 182, 77, 1) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tabs>.item {
  color: white;
  font-size: x-small;
  margin-top: 0.35rem;
  border: none;
}

// SettingComponent
div.b-slider-thumb {
  border-radius: unset !important;
  background-color: white !important;
}

.my-modal {
  border-radius: 30px;
  background-color: #fff;
  //padding: 60px 12px 60px 12px
}

.fade-enter-active {
  animation-duration: 0.3s !important;
}

.fade-leave-active {
  animation-duration: 0.3s !important;
}

.responsive-image {
  //width: calc(100vw / 3);
  max-width: 256px;
}

.responsive-image-fa {
  width: calc(100vw / 3);
  max-width: 256px;
}

.thumb-svg {
  object-fit: cover;
  border-radius: 12px;
  filter: invert(27%) sepia(3%) saturate(128%) hue-rotate(314deg) brightness(92%) contrast(83%);
}

.thumb-img {
  object-fit: cover;
  border-radius: 12px;
}

.original-file-name {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

summary {
  outline: none;
}

.margin-top-6px {
  margin-top: 6px
}

.margin-top-12px {
  margin-top: 12px
}

.margin-top-18px {
  margin-top: 18px
}

.margin-top-24px {
  margin-top: 24px
}

.margin-top-30px {
  margin-top: 30px
}

.margin-top-60px {
  margin-top: 60px
}

.margin-top-72px {
  margin-top: 72px
}

.margin-top-90px {
  margin-top: 90px
}

.margin-bottom-6px {
  margin-bottom: 6px
}

.margin-bottom-12px {
  margin-bottom: 12px
}

.margin-bottom-18px {
  margin-bottom: 18px
}

.margin-bottom-24px {
  margin-bottom: 24px
}

.margin-bottom-30px {
  margin-bottom: 30px
}

.margin-bottom-60px {
  margin-bottom: 60px
}

.margin-bottom-120px {
  margin-bottom: 120px
}

.padding-top-30px {
  padding-top: 30px;
}

.upload .upload-draggable {
  border: none !important;
}

.pagination-link.is-current {
  color: white !important;
}

// pagination buttons List.vue
nav>a.pagination-link {
  display: none;
}

div.navbar-menu {
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
}

div.navbar-dropdown {
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
}

// List <b-taginput> オートコンプリート時のサジェストを全て表示するようにする
// default display: block
.autocomplete .dropdown-menu {
  display: contents !important;
}

header.message-header {
  border-radius: 12px 12px 0 0;
}

article.message {
  border-radius: 12px;
}

// b-message のヘッダーを中央にする
header.message-header>p {
  margin: 0 auto;
}

// FAQ
p.accordion__title-text {
  font-weight: bold;
}

div.video-js {
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

video.vjs-tech {
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

@import "bulma/bulma.sass";
@import "buefy/src/scss/buefy";

$animationDuration: 1s;
@import "vue2-animate/src/sass/vue2-animate.scss";
</style>
