<template>
  <b-button v-if="!$store.state.isLoggedIn && !$store.state.isLoginProcessing" class="has-text-weight-bold is-primary"
    @click="signUp(redirect_uri)" rounded>アカウント作成
    <span class="has-text-white-ter">ー無料</span></b-button>
</template>

<script>
export default {
  name: "ButtonSignUp",
  props: {
    redirect_uri: {
      type: String,
      default: '/dashboard'
    }
  }
}
</script>

<style lang="scss" scoped></style>
