export default {
  methods: {
    DeleteGroup: function (id) {
      this.$buefy.dialog.confirm({
        message: this.$t('dashboard.group.delete.message'),
        confirmText: this.$t('delete.delete'),
        cancelText: this.$t('cancel'),
        type: 'is-info',
        ariaRole: 'alertdialog',
        ariaModal: true,
        ariaLabel: 'グループの削除',
        onConfirm: () => {
          this.openLoading()

          this.$axios.delete(`/group?id=${id}`, {headers: {'Authorization': `Bearer ${this.$store.state.token}`}})
            .then(() => {
              this.closeLoading()
              window.location.reload()
            }).catch(() => {
            this.closeLoading()
            this.toastError(`グループの削除に失敗しました。このエラーは報告済みです。`)
          })
        },
        onCancel: () => {
        },
      })
    },
  }
}
