<script setup>
import { defineProps } from 'vue';

const props = defineProps({
  isDark: Boolean,
  isCenter: Boolean,
});
</script>

<template>
  <div :class="{ 'app-link-container': true, 'center': isCenter }">
    <div class="app-link">
      <a href="https://apps.apple.com/jp/app/hstorage-%E7%9B%B4%E3%83%AA%E3%83%B3%E3%82%AF%E3%82%82%E3%81%A7%E3%81%8D%E3%82%8B%E3%82%AF%E3%83%A9%E3%82%A6%E3%83%89%E3%82%B9%E3%83%88%E3%83%AC%E3%83%BC%E3%82%B8/id6449781149?itsct=apps_box_badge&amp;itscg=30200"
        rel="noreferrer" target="_blank">
        <img
          :src="props.isDark ? '/static/images/app-store-badge-dark.svg' : '/static/images/app-store-badge-light.svg'"
          alt="Download on the App Store" loading="lazy">
      </a>
    </div>
    <div class="app-link">
      <a href='https://play.google.com/store/apps/details?id=ltd.hcloud.hstorage_app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
        rel="noreferrer" target="_blank">
        <img alt="Google Play で手に入れよう"
          :src="props.isDark ? '/static/images/google-play-badge-dark.svg' : '/static/images/google-play-badge-light.svg'"
          loading="lazy">
      </a>
    </div>
    <div class="app-link">
      <a href="https://chromewebstore.google.com/detail/hstorage-screen-capture/bhgefolehpgmkchigfljdbaoejhjadcn"
        rel="noreferrer" target="_blank">
        <img alt="Chrome Web Storeで入手" src="/static/images/chrome-web-store.svg" loading="lazy">
      </a>
    </div>
  </div>
</template>

<style scoped lang="scss">
.app-link-container {
  display: flex;
}

.app-link {
  width: 150px;
}

.center {
  justify-content: center;
}
</style>
